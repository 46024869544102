import { render, staticRenderFns } from "./modu.vue?vue&type=template&id=51b8c67c&scoped=true"
import script from "./modu.vue?vue&type=script&lang=js"
export * from "./modu.vue?vue&type=script&lang=js"
import style0 from "./modu.vue?vue&type=style&index=0&id=51b8c67c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51b8c67c",
  null
  
)

export default component.exports