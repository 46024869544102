<template>
    <custom-dialog
      :visible.sync="visible"
      :title="title"
      @close="colseDialog"
      @submit="submit"
    
      width="45%"
    >
      <div class="editTitle">模块基本信息</div>
  
      <div class="edit-main">
        <el-form
          :model="editMenuForm"
          label-width="120px"
          :rules="rules"
          ref="editVersionForm"
        >
          <el-row >
            <el-col :span="12">
              <el-form-item label="功能名称" prop="moduName">
                <el-input size="small" v-model="editMenuForm.moduName" placeholder="请输入功能名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标识" prop="moduTag">
                <el-input size="small" v-model="editMenuForm.moduTag" placeholder="请输入标识"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="moduState">
                <el-select class="block-select" size="small" v-model="editMenuForm.moduState" placeholder="请选择状态">
                  <el-option label="启动" :value="0"></el-option>
                  <el-option label="未启动" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" prop="moduType">
                <el-select class="block-select" size="small" v-model="editMenuForm.moduType" placeholder="请选择类型">
                  <el-option label="目录" :value="0"></el-option>
                  <el-option label="菜单" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属目录" prop="parentId">
                <el-select class="block-select" size="small" v-model="editMenuForm.parentId" placeholder="请选择所属目录">
                  <el-option v-for="(item, index) in parentList" :key="index" :label="item.moduName" :value="item.moduId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址" prop="moduUrl">
                <el-input size="small" v-model="editMenuForm.moduUrl" placeholder="请输入地址"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="图标" prop="moduIcon">
                <el-select class="block-select icon-select" size="small" v-model="editMenuForm.moduIcon" placeholder="请选择图标">
                  <el-option v-for="(item, index) in iconList" :key="index" :label="item.value" :value="item.value">
                    <i :class="['icon-select','iconfont',item.value]"></i> {{item.value}}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="排序" prop="moduSort">
                <el-input size="small" v-model="editMenuForm.moduSort" placeholder="请输入排序"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="moduNote">
                <el-input
                size="small"
                type="textarea"
                :rows="2"
                v-model="editForm.moduNote"
                placeholder="请输入备注"
              ></el-input>
              </el-form-item>
            </el-col>
         
           
          </el-row>
        </el-form>
      </div>
    </custom-dialog>
  </template>
  
  <script>
  import CustomDialog from "../../components/common/CustomDialog.vue";
  import config from "../../api/config";
  import sysManage from'../../api/modules/sysManage'
//   import versionMange from "../../api/modules/versionMange";
//   import { equipType } from "../../api/modules/equipType";
  
  export default {
    name: "moduEdit",
    components: { CustomDialog },
    props: ["visible", "title",'item'],
    data() {
      return {
        editForm: {},
        rules:{
        moduName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        moduTag: [
          { required: true, message: '请输入标识', trigger: 'blur' },
        ],
        moduState: [
          { required: true, message: '请选择状态', trigger: 'change' },
        ],
        moduType: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
        parentId: [
          { required: true, message: '请选择所属目录', trigger: 'change' },
        ],
        moduUrl: [
          { required: true, message: '请输入地址', trigger: 'blur' },
        ],
        moduSort: [
          { required: true, message: '请输入排序', trigger: 'blur' },
        ],
      },
        uploadUrl: config.uploadUrl,
        editMenuForm:{},
        equipType: [],
        parentList:[]
        // equipType: equipType
      };
    },
    watch:{
    item(newVal,oldVal){
      if(newVal.moduId){
        
        this.editMenuForm = newVal
       
      }else {
        
        this.editMenuForm = {
            moduName:'',
            moduTag:'',
            moduState:'',
            moduType:'',
            parentId:'',
            moduUrl:'',
            moduSort:''
        }
      }
    //   this.resetFields()
    }
  },
    mounted(){
        this.getModule()
    },
    methods: {
      colseDialog() {
        this.$emit("close");
      },
      
      //获取摸块下拉框
      getModule(){
        sysManage.getModuleList({moduleType:0}).then(res=>{
            console.log('res',res);
            this.parentList = [ ...res.data]
           
         
        })
      },
      submit() {
       
        this.$refs.editVersionForm.validate((valid) => {
          if (valid) {
            if(this.editMenuForm.moduId){
                sysManage.editModuleList(this.editMenuForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("done");
            });
            }else{
                sysManage.addModuleList(this.editMenuForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
            });
            }
           
          }
        });
      },
     
    },
  };
  </script>
  
  <style scoped lang="less">
  .editTitle {
    border: 1px solid #eeeeee;
    border-bottom: 0px solid  #eeeeee;
    padding: 10px 0 10px 30px;
    border-radius: 6px 6px 0px 0px;
    font-weight: bold;
  }
  .edit-main {
    padding: 20px;
    border: 1px solid #eeeeee;
    border-radius: 0px 0px 6px 6px;
  }
  </style>
  