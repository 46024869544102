<template>
    <div class="pageMain">
      <search-head :model="searchForm" @search="search" @reset="reset">
        <el-form :inline="true" :model="searchForm">
            <el-form-item label="功能名称">
          <el-input v-model="searchForm.moduleName" size="small" placeholder="功能名称"></el-input>
        </el-form-item>
        <el-form-item label="功能标识">
          <el-input v-model="searchForm.moduTag" size="small" placeholder="功能标识"></el-input>
        </el-form-item>
        <el-form-item label="所属模块">
          <el-select class="block-select" size="small" v-model="searchForm.moduleType" placeholder="请选择类型">
                  <el-option label="目录" :value="0"></el-option>
                  <el-option label="菜单" :value="1"></el-option>
                </el-select>
        </el-form-item>
      </el-form>
      </search-head>
      <custom-tables :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
                   @handleSizeChange="handleSizeChange"
        
          :total="total"
          title="功能列表">
        <template slot="btns">
          <el-button   type="success" size="small" @click="addFrom" icon="el-icon-plus" class="addBtn">新增</el-button>
        </template>
        <el-table-column type="selection" align="center" width="55">
      </el-table-column>
        <el-table-column
            prop="moduName"
            label="功能名称"
            align="center"
          >
        </el-table-column>
        <el-table-column
            prop="moduType"
            align="center"
            label="所属模块"
          >
          <template slot-scope="scope">
          <div v-if="scope.row.moduType == 0">目录</div>
          <div v-if="scope.row.moduType == 1">菜单</div>
        </template>
        </el-table-column>
        <el-table-column
            prop="moduTag"
            align="center"
            label="功能标识">
        </el-table-column>
        <el-table-column
            prop="moduUrl"
            align="center"
            label="功能地址">
        </el-table-column>
        <el-table-column
            prop="moduSort"
            align="center"
            label="顺序">
        </el-table-column>
        <el-table-column
            prop="moduState"
            align="center"
            label="状态">
            <template slot-scope="scope">
          <el-tag v-if="scope.row.moduState == 0">启用</el-tag>
          <el-tag v-if="scope.row.moduState == 1" type="danger" >未启用</el-tag>
        </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="添加时间">
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >
              <i class="el-icon-edit"></i>
            </span>
            <span
                @click="deleteItem(scope.row)"
                class="detail delete"
              >
              <i class="el-icon-delete"></i>
            </span>
            </template>
          </el-table-column>
      </custom-tables>
  
      <moduEdit  :visible.sync="showDialog" @close="colseDialog" :title="title" @done="doneDialog" :item="item">
    
  </moduEdit>
    </div>
  </template>
  
  <script>
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import moduEdit from "../../../components/systemManage/moduEdit.vue";
  import sysManage from '../../../api/modules/sysManage'
  export default {
    name: "index",
    components: {moduEdit, CustomTables, SearchHead},
    data() {
      return {
        tableData: [
         
        ],
        searchForm:{
          currPage: 1,
        pageSize:10
        },
        total:0,
        showDialog: false,
        headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      title:'',
      item:{}
      }
    },
    mounted() {
      this.getList()
    },
    methods:{
      getList(){
        sysManage.getModuleListByPage(this.searchForm).then(res=>{
        
          this.total = res.data.total
        this.tableData = res.data.list
        })
      },
      search(){
        console.log('searchForm', this.searchForm)
        this.searchForm.currPage = 1
      this.searchForm.pageSize = 10
   
      this.getList()
      },
      reset(){
        this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getList();
      },
      addFrom(){
        this.item={}
        this.showDialog = true
        this.title='模块管理新增'
      },
      colseDialog(){
        this.showDialog = false
        this.getList();
      },
      doneDialog(){
        this.showDialog = false
        this.getList()
      },
      submit(){
        this.showDialog = false
      },
      detailItem(row){
        //详情
        this.item=row
        this.showDialog = true
        this.title='模块管理编辑'

    },
    deleteItem(item){
        // 删除菜单
        this.$confirm(`此操作将永久删除该菜单, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sysManage.deleteModuleList({moduId:item.moduId}).then(res =>{
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
    handleSelectionChange(val){
        console.log(val);
        
    }
  
    }
  }
  </script>
  
  <style scoped lang="less">
  .detail{
    color: #008f4d;
}
.delete{
    margin-left: 10px;
}
.addBtn{
    background-color:#008f4d;
  
}
  </style>